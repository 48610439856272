import React from 'react'
import rlogo from '../assets/images/r_logo.svg';

const HeaderR = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={rlogo} alt="Python vs Java" /></span>
        <h1>Python vs R</h1>
        <h2>Comparison of Python and R.</h2>
    </header>
)

export default HeaderR
