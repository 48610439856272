import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import rlogo from '../assets/images/r_logo.svg'
import HeaderR from '../components/HeaderR'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import r1 from '../assets/images/r1.png'
import r2 from '../assets/images/r2.png'
import r3 from '../assets/images/r3.png'
import r4 from '../assets/images/r4.png'
import r5 from '../assets/images/r5.png'
import r6 from '../assets/images/r6.png'
import r7 from '../assets/images/r7.png'
import r8 from '../assets/images/r8.png'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Python vs R - Comparison - pythonvs.com" />
        <HeaderR />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What is R?</h2>
                </header>
                <p>R is a programming language created in 1993, that is often used by statisticians and data analysts. It is also popular among academics. If you work closely with data, R could be the language for you.</p>

                <p>However, that’s also often said about Python. In fact, these two languages have been often compared, because they both can be used for data analysis.</p>

                <p>When it comes to popularity, the <a href="https://www.tiobe.com/tiobe-index/">TIOBE Index</a> for August 2020 put R in 8th place. A year ago, in July 2019, it was in 20th place. R is also on the list of the <a href="https://insights.stackoverflow.com/survey/2020#most-loved-dreaded-and-wanted">Most Loved Languages</a> in the Stack Overflow's Developer Survey 2020, and it’s higher on that list than some of the most popular languages out there.</p>

                <p>Even if it’s not among the most popular programming languages, it is well liked by those who use it.</p>


              </div>
              <span className="image">
                <img src={rlogo} alt="Python vs Java" />
              </span>
            </div>
          </section>
          
          <section className="main">
            <header className="major">
              <h2>Python vs R</h2>
            </header>
            <p>Both R and Python are open-source programming languages with large communities. They both perform superbly well with data analysis, but with different caveats. R is built by statisticians and as such encompasses their specific language; it is very popular in academic studies and scientific modeling. Python, however, offers a more general approach to data science, and a multitude of opportunities in different areas. So what if you want to do a data-heavy project? If you wonder which one will win the Python vs. R data science battle, we compared them to see what are the most important aspects that can determine which one is best. This is <span>R vs. Python: the final throw down</span>.</p>
            <h2>Python vs. R: in numbers</h2>
            <h3>TIOBE Index</h3>
            <p><span>The TIOBE Programming Community index is an indicator of the popularity of programming languages compiled monthly and based on the number of skilled engineers world-wide, courses and third party vendors using a given language. </span><span>You can read more about the index and the organization at: </span><a href="https://www.tiobe.com/" target="_blank" rel="noopener"><span>https://www.tiobe.com/</span></a></p>
            <img src={r6} alt="Python vs R" />
            <ul>
              <li><span>Python has been climbing the TOBIE rankings in recent years, jumping to the second place just in November 2020 and still rising at an impressive rate. R is also rising in popularity, at even faster rate (relative to its small user base), with its popularity soaring from 16th place in November 2019 to top 9 in November 2020.</span></li>
              <img src={r8} alt="Python vs R" />
            </ul>
            <ul>
              <li><span>TIOBE indices over the years indicate that R is slowly but consistently building its popularity, from 46th place in 2010 and 9th already a decade later (now, in 2020). Python has carved its way to the top 3 in the last couple of years, starting in the rankings already in 1995.&nbsp;</span></li>
            </ul>
            <ul>
              <li><span>Python has been TOBIE&rsquo;s language of the year three times, and was most recently crowned in 2018. R was never awarded this title, but its narrow applications may be the reason why.&nbsp;</span></li>
            </ul>
            <h3>Stack Overflow</h3>
            <p><span>Stack Overflow&rsquo;s 2020 was the 9th consecutive year for the survey with 90,000 developers responding. </span><span>More information on SO&rsquo;s website: </span><a href="https://insights.stackoverflow.com/" target="_blank" rel="noopener"><span>https://insights.stackoverflow.com/</span></a></p>
            <center>
            <img src={r7} alt="Python vs R" /><br />
            <img src={r4} alt="Python vs R" />
            <img src={r1} alt="Python vs R" />
            </center>
            <br />
            <ul>
              <li>Python is far more popular according to Stack Overflow&rsquo;s survey with 40% of professionals and 44% of all respondents using the technology. R gathers around 5-6% of users amongst the respondents, but its use in scientific programming may mean that surveys of coders may not catch all its users.&nbsp;</li>
            </ul>
            <h3>GitHub users</h3>
            <p>GitHub is one of the world&rsquo;s largest code development platforms. It is the largest host of source code in the world with more than 40 million users and over 100 million repositories (including at least 28 million public repositories). Ben Frederickson used GitHub&rsquo;s archives to track programming languages its users write code in. Every user interaction with a repository is counted as using the language of that repository. The aggregated number gives the Monthly Active Users (MAU) each language has. <span>More info on methodology and sources: </span><a href="https://www.benfrederickson.com/ranking-programming-languages-by-github-users/" target="_blank" rel="noopener"><span>https://www.benfrederickson.com/ranking-programming-languages-by-github-users/</span></a></p>
            <center>
            <img src={r5} alt="Python vs R" />
            <img src={r2} alt="Python vs R" />
            </center>
            <ul>
              <li>R has minuscule following amongst GitHub users in comparison to Python, will less than 1% and almost 15% respectively. But once again, as R is mostly used by data scientists they may be using different resources than your typical developer.</li>
            </ul>
            <h3>Other rankings comparing Python vs. R in numbers</h3>
            <ul>
              <li><span>How likely programmers are to switch a language was measured using blog posts discussing moving from one language to another in order to rank programming languages by </span><a href="https://erikbern.com/2017/03/15/the-eigenvector-of-why-we-moved-from-language-x-to-language-y.html" target="_blank" rel="noopener">Erik Bernhardsson</a></li>
              <li><span>Number of people transitioning languages on GitHub was used by the team at </span><a href="https://blog.sourced.tech/post/language_migrations/" target="_blank" rel="noopener">sourced</a></li>
            </ul>
            <h2>R vs. Python from the managerial perspective&nbsp;</h2>
            <ul>
              <li>
                <h4>R vs. Python: speed and efficiency</h4>
              </li>
              <ul>
                <li><span>Points for Python</span></li>
                <li>Python is much faster in execution for the majority of tasks. For instance, loop execution in the language is over 5x faster than in R.&nbsp;&nbsp;</li>
              </ul>
              <li>
                <h4>Python vs. R for data science output</h4>
              </li>
              <ul>
                <li><span>Points for R</span></li>
                <li>One of the best selling points of R, its cutting-edge difference setting it apart from other statistical products, is undoubtedly its output. R has impressive tools to communicate the results obtained, with, for example, knitr library in Rstudio.&nbsp;</li>
                <li>Knitr was written by Xie Yihui, who made reporting both trivial and elegant. The package allows you to easily transform your results into output presentable in various media. It&rsquo;s known for creating easily readable and visually appealing graphs and other data visualizations.</li>
              </ul>
              <li>
                <h4>Applications</h4>
              </li>
              <ul>
                <li><span>Issues with R</span></li>
                <li>R is great to use for non-developers, but the fact that it&rsquo;s being developed by statisticians and academics in a way obstructs its venturing out into other areas.&nbsp;</li>
                <li><span>Points for R</span></li>
                <li>R is primarily used in research and academia and works really well in explanatory data analysis.&nbsp;</li>
                <li>It&rsquo;s used by researchers, scientists, and engineers who often lack a background in programming, and as such is often used in finance, pharmaceuticals, media, and marketing.</li>
                <li><span>Points for Python</span></li>
                <li>Python is much more versatile than R. It&rsquo;s widely used in artificial intelligence, data analytics, deep learning, and web development, with growing applications in fintech.</li>
                <li>It&rsquo;s a general programming language, with which you can build a variety of programs, not only data-related solutions</li>
              </ul>
              <li>
                <h4>Data handling</h4>
              </li>
              <ul>
                <li><span>Points for R</span></li>
                <li>R works really well for data analysis, as it has a huge number of packages, readily usable tests, and the benefit of using formulas.</li>
                <li>It handles basic data analysis without the need to install any packages and has a vast array of packages when more complex data handling is required.</li>
                <li><span>Issues with Python</span></li>
                <li>Python requires installing packaging in order to utilize its data analytical capabilities. However, these packages have been improving greatly over the years and can offer a multitude of functionalities.</li>
              </ul>
            </ul>
            <h4>Python vs. R: machine learning</h4>
            <ul>
              <li><span>Points for Python</span></li>
              <li>Machine learning prioritizes predictive accuracy over model&rsquo;s interpretability, and Python, a language which capabilities lie exactly there, has become the preferred language for machine learning.&nbsp;</li>
              <li>Not only is it naturally disposed towards machine learning, Python&rsquo;s array of packages further help optimize for the process. Some powerful packages include PyBrain, a modular machine learning library, or scikit-learn, built on NumPy and SciPy, which offers tools helpful in data mining and analytics.</li>
            </ul>
            <ul>
              <li>
                <h4>Deployment</h4>
              </li>
              <ul>
                <li><span>Points for Python</span></li>
                <li>Python excels in deploying models into other pieces of software. As a general-purpose programming language with concise coding, it&rsquo;s great for rapid prototyping and later on deploying these prototypes as full-fledged apps with minimal rewrites.&nbsp;</li>
                <li>It also sports much better reproducibility than R, mostly because it can create functional programs rather than just run calculations.</li>
              </ul>
            </ul>
            <h2>Python vs. Go from a developer perspective&nbsp;</h2>
            <p>Developers focus on different features when looking for a language to learn and develop in.</p>
            <ul>
              <li>
                <h4>Usability</h4>
              </li>
              <ul>
                <li><span>Points for R</span></li>
                <li>R is very easy to use even for people without a background in programming. Statistical methods can be called using just a few lines of code.</li>
                <li>The same functionality can be written in various ways, giving flexibility to the users.</li>
                <li><span>Issues with R</span></li>
                <li>Although initially easy to learn (although some disagree), complex solutions in R require significant effort to master, in comparison to a flatter learning curve of Python.</li>
                <li><span>Points for Python</span></li>
                <li>Python is often considered to be one of the easiest programming languages to learn due to its simple and readable syntax. People with a background in software engineering may find it easier to use than R.</li>
                <li>Python usually has only one way of coding a certain functionality, decreasing variability in solutions for particular problems.</li>
              </ul>
            </ul>
            <ul>
              <li>
                <h4>Ecosystem</h4>
              </li>
              <ul>
                <li><span>Points for R</span></li>
                <li>R has a robust ecosystem of cutting-edge packages, allowing for communication between open-source languages. This allows the users to integrate their workflows with team members, which comes especially handy when doing data analytics.</li>
                <li>Packages in R are collections of R functions, data, and already compiled code. They can be called up in R using just one line of code.</li>
                <li><span>Points for Python</span></li>
                <li>Because of its famously readable and simple syntax and a multitude of libraries with a wide range of applications available it&rsquo;s relatively easy to construct complex applications.</li>
                <li>It&rsquo;s a great tool for building data science pipelines and machine learning solutions integrated with web-based frameworks at scale.&nbsp;</li>
              </ul>
            </ul>
            <p><span>.</span></p>
            <h2>Python vs. R: summary</h2>
            <p>The Python vs. R debate really has only one dimension: which one is better for data analysis? As a general programming language, Python handles everything else much better (or at all). However, when it comes to statistical modeling and creating beautiful, legible, and satisfying data visualizations R is the king. Easy to use for scientists who are not trained in programming, and offering a robust set of packages and libraries, it comes in handy in many areas of research and academia. Although it may excel in data analytics, it&rsquo;s key to remember that it&rsquo;s a scientific language with few (although growing) commercial applications. If you want to do anything more than manage and study your data, let&rsquo;s say deploy a model based on your findings, Python will be much handier. If you&rsquo;re building a team made up mostly of researchers, R may be easier for them to familiarize with. But if you wanna build anything functional, you should turn to Python and its vast applications. Its succinct, elegant code is readable, speedy, and offers many functionalities far beyond data handling.</p>
            <p><span>Python vs. R: differences and uses</span></p>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Python</td>
                    <td>R</td>
                  </tr>
                  <tr>
                    <td>Good for</td>
                    <td>Statistics, data analyticsMachine learningfintechData-heavy sites and servers with high-traffic volume</td>
                    <td>Statistical analysisData analyticsEconomic/social sciences modelingScientific modeling</td>
                  </tr>
                  <tr>
                    <td>Bad for&nbsp;</td>
                    <td>Data processing&mdash;it is not designed to perform well in highly specialized apps for data processingMobile development</td>
                    <td>Anything that is not data analysis; it&rsquo;s a scientific language created with statistical analysis in mind;It has few commercial applications</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Python vs Other Programming Languages</h2>
            </header>
            <ul className="features">
              <li>
                <h3><Link to="/java">Python vs Java</Link></h3>
                <p>
              Python to Java comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/r">Python vs R</Link></h3>
                <p>
                 Python to R comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/cplusplus">Python vs C++</Link></h3>
                <p>
                  Python to C++ comparison.
                </p>
              </li>
              <li>
                  <h3><Link to="/nodejs">Python vs Node.js</Link></h3>
                <p>
                  Python to Node.js comparison.
                </p>
              </li>

              <li>
                  <h3><Link to="/php">Python vs PHP</Link></h3>
                <p>
                  Python to PHP comparison.
                </p>
              </li>
              <li>
                <h3><Link to="/go">Python vs GO</Link></h3>
                <p>
                  Python to GO comparison.
                </p>
              </li>
            </ul>

          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>R in numbers</h2>
              <p>
                Statistics of R programming language based on <a href="https://www.tiobe.com/tiobe-index/">TIOBE index</a>, <a href="https://insights.stackoverflow.com/survey/2020">Stackoverflow Developer Survey 2020</a> and <a href="https://www.jetbrains.com/lp/devecosystem-2020/">The State of Developer Ecosystem 2020</a> by Jetbrains.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                Number
                <strong>8</strong> in TIOBE index
              </li>
              <li className="style2">
                Used by
                <strong>5,5%</strong>Developers
              </li>
              <li className="style3">
                Loved by
                <strong>44,1%</strong>Developers
              </li>
              <li className="style4"> Primary language
                <strong>1%</strong>Developers
              </li>
              <li className="style5">
                Want to learn
                <strong>5,1%</strong> Developers              </li>
            </ul>


          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Need custom software development services?</h2>
              <p>

                Visit our site
                <br />
                to find out more about services we offer.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://www.stxnext.com/services/" className="button special">
                    Custom Software Development Services
                  </a>
                </li>
                <li>
                  <a href="https://www.stxnext.com/portfolio/" className="button">
                    View our Portfolio
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
